

import util from 'utility';
import scrollTo from './objects/scrollTo';
import browser from './global/browser';


// vars

let cookieBannerHeight = 0;

let selectors = {
	cookieBanner : '.m-cookie-policy',
	cookieBannerClose : '.m-cookie-policy__close'
}

let heroElem,
	cookieBannerElem,
	cookieBannerCloseElem;


// funcs

function heroScrollInit(heroElem) {
	
	const scrollHint = heroElem.querySelector('.js-scroll-hint');
	if (!scrollHint) return;

	scrollHint.addEventListener('click', function() {
		const rect = heroElem.getBoundingClientRect();
		const top = rect.top + (window.scrollY  || window.pageYOffset || document.documentElement.scrollTop) - document.documentElement.clientTop;
		const bottom = top + rect.height;
		scrollTo.scrollToPosition(bottom);
	});
}


function heroScrollOpacityInit(heroElem) {

	if (!heroElem || !IntersectionObserver in window) return;

	const scrollHint = heroElem.querySelector('.js-scroll-hint');
	if (!scrollHint) return;

	const thresholds = (function() {
		const array = [];
		for (let i=0; i<=1.0; i+= 0.01) {
			array.push(Math.round(i * 1e2) / 1e2);
		}
		return array;
	})();

	function observerCallback(entries, observer) {
		entries.forEach((entry) => {

			const crossedTop = (entry.boundingClientRect.y < entry.rootBounds.y) ? 1 : 0;
			const percentScrolled = crossedTop && 1 - entry.intersectionRatio;
			
			if (!entry.isIntersecting) return;

			const multiplier = 2;

			// multiplying then dividing by 1e2 (100) after Math.round 
			// rounds the opacity to 2 decimal places
			let opacity = 
				Math.round(
					((1 - percentScrolled) * 1*multiplier - percentScrolled)
					* 1e2
				) / 1e2
			;

			opacity = Math.min(1, opacity);
			opacity = Math.max(0, opacity);

			scrollHint.style.opacity = opacity;		
		});
	}

	const intersectionObserver = new IntersectionObserver(observerCallback, {
		threshold : thresholds
	});

	intersectionObserver.observe(heroElem);
}


function measureCookieBannerHeight() {
	if (!cookieBannerElem) return;
	// copy previously set height
	const height = cookieBannerElem.style.height;
	// remove height property and measure
	cookieBannerElem.style.height = '';
	cookieBannerHeight = cookieBannerElem.clientHeight;
	// return original height property
	cookieBannerElem.style.height = height;
	return cookieBannerHeight;
}


function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}


function heroHeightInit(heroElem) {

	const isOldIE = browser.detectIE() && browser.detectIE() <= 11;

	// init hero/cookiebanner height
	cookieBannerElem = document.querySelector(selectors.cookieBanner);
	cookieBannerCloseElem = cookieBannerElem && cookieBannerElem.querySelector(selectors.cookieBannerClose);

	// if no cookie banner, just use the standard css
	if (!cookieBannerElem || !cookieBannerCloseElem) return;

	// if it's the small header, don't set height to full viewport height
	if (hasClass(heroElem, 'm-hero--small')) return;

	// get viewport height
	const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

	// set height
	function setHeight(heroElem, vh = 0, cookieBannerHeight = 0, doAnimation = false) {
		const heroHeight = vh - cookieBannerHeight;
		!doAnimation && (heroElem.style.transition = 'none');
		!doAnimation && (heroElem.getBoundingClientRect());
		heroElem.style.minHeight = heroHeight + 'px';
		isOldIE && (heroElem.style.height = heroHeight + 'px');
		!doAnimation && (heroElem.getBoundingClientRect());
		!doAnimation && (heroElem.style.transition = '');
	}
	setHeight(heroElem, vh, measureCookieBannerHeight(), false);

	// set height on window resize (debounced)
	window.addEventListener('resize', util.debounce(() => {
		const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		setHeight(heroElem, vh, measureCookieBannerHeight(), false);
	}, 50));	
}


// export

export const initInstance = function(el) {
	heroElem = el;
	heroScrollInit(heroElem);
	heroScrollOpacityInit(heroElem);
	heroHeightInit(heroElem);
}
